import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const initialState = {}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
